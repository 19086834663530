@font-face {
    font-family: 'Gibson';
    src: url('../public/fonts/Gibson-Regular.otf');
}

* {
    font-family: 'Gibson';
}

/*This change was necessary to ensure that the feedback 
button is always placed below the content*/
:root main {
    height: 96vh;
    padding-bottom: 10px;
}